import React, { useState } from 'react';
import BusinessKitzLogo from '../../../static/assets/svg/business-kitz-logo.svg';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import LinkItem from './components/LinkItem/LInkItem';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ClarifyIcon from '../../../static/assets/svg/menu/clarify.svg';
import PackageIcon from '../../../static/assets/svg/menu/package-2.svg';
import ContractEditIcon from '../../../static/assets/svg/menu/contract-edit.svg';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import BusinessCenterIcon from '../../../static/assets/svg/menu/business-center.svg';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutIcon from '../../../static/assets/svg/menu/logout.svg';

const MainNavigation = () => {
  const { t } = useTranslation();
  const [expandedView, setExpandedView] = useState<boolean>(false);
  return (
    <>
      {expandedView && (
        <div
          tabIndex={0}
          role='button'
          className='navigation-under-layer'
          onClick={() => setExpandedView(false)}
          onKeyDown={() => {
            return;
          }}
        />
      )}
      <nav className={'main-navigation' + (expandedView ? ' expanded-view' : '')}>
        {expandedView ? (
          <div className='logo-wrapper'>
            <BusinessKitzLogo />
          </div>
        ) : (
          <div
            role='button'
            tabIndex={0}
            className='menu-control-wrapper'
            onClick={() => setExpandedView(true)}
            onKeyDown={() => {
              return;
            }}
          >
            <MenuOutlinedIcon fontSize='large' />
          </div>
        )}
        <ul className='list-unstyled'>
          <li>
            <LinkItem active={true} expandedView={expandedView} linkText={t('menu.home')}>
              <CottageOutlinedIcon fontSize='medium' />
            </LinkItem>
          </li>
        </ul>
        <div className='section-label'>{t('menu.documents')}</div>
        <ul className='list-unstyled with-border'>
          <li>
            <LinkItem active={false} expandedView={expandedView} linkText={t('menu.library')}>
              <AccountBalanceOutlinedIcon fontSize='medium' />
            </LinkItem>
          </li>
          <li>
            <LinkItem active={false} expandedView={expandedView} linkText={t('menu.workspace')}>
              <ClarifyIcon />
            </LinkItem>
          </li>
          <li>
            <LinkItem active={false} expandedView={expandedView} linkText={t('menu.vault')}>
              <PackageIcon />
            </LinkItem>
          </li>
          <li>
            <LinkItem active={false} expandedView={expandedView} linkText={t('menu.sign')}>
              <ContractEditIcon />
            </LinkItem>
          </li>
        </ul>
        <div className='section-label'>{t('menu.profiles')}</div>
        <ul className='list-unstyled'>
          <li>
            <LinkItem active={false} expandedView={expandedView} linkText={t('menu.business')}>
              <ApartmentOutlinedIcon fontSize='medium' />
            </LinkItem>
          </li>
          <li>
            <LinkItem active={false} expandedView={expandedView} linkText={t('menu.employees')}>
              <BadgeOutlinedIcon fontSize='medium' />
            </LinkItem>
          </li>
          <li>
            <LinkItem active={false} expandedView={expandedView} linkText={t('menu.customers')}>
              <SupportAgentOutlinedIcon fontSize='medium' />
            </LinkItem>
          </li>
          <li>
            <LinkItem active={false} expandedView={expandedView} linkText={t('menu.suppliers')}>
              <BusinessCenterIcon />
            </LinkItem>
          </li>
        </ul>
        <ul className='list-unstyled mt-auto with-border'>
          <li>
            <LinkItem active={false} expandedView={expandedView} linkText={t('menu.plans')}>
              <SellOutlinedIcon fontSize='medium' />
            </LinkItem>
          </li>
        </ul>
        <ul className='list-unstyled'>
          <li>
            <LinkItem active={false} expandedView={expandedView} linkText={t('menu.settings')}>
              <SettingsOutlinedIcon fontSize='medium' />
            </LinkItem>
          </li>
          <li>
            {expandedView ? (
              <span className='main-nav-link'>
                <LogoutIcon />
                <span className='main-nav-link-text'>{t('menu.logout')}</span>
              </span>
            ) : (
              <Tooltip title={t('menu.logout')} placement='top' arrow>
                <span className='main-nav-link'>
                  <LogoutIcon />
                </span>
              </Tooltip>
            )}
          </li>
        </ul>
      </nav>
    </>
  );
};

export default MainNavigation;
