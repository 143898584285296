import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NotificationsUnreadIcon from '../../../static/assets/svg/notifications-unread.svg';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Container } from '@mui/material';
import Switch from '@mui/material/Switch';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { switchDesign } from '../../slices/design-switch/designSwitchSlice';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ROUTES } from '../constants/routes';

const AppBar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  const dispatch = useAppDispatch();
  const designUpdated = useAppSelector((state) => state.designUpdated.designUpdated);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(switchDesign(event.target.checked));
  };
  return (
    <div className='application-bar'>
      <Container maxWidth='lg' className='d-flex justify-content-between align-items-center'>
        <div className='name-date-container'>
          <div className='name-container'>Good afternoon, Jim</div>
          <div className='date-container'>03 February 2025</div>
        </div>
        <div className='actions-container d-flex align-items-center'>
          {path === ROUTES.INDEX && process.env.ENV_TYPE !== 'PROD' && (
            <FormControlLabel
              control={<Switch checked={designUpdated} onChange={handleChange} />}
              label={'Updated design version'}
            />
          )}
          <Link className='app-bar-link' to={null}>
            {t('buttons.browseBusinessKitzLibrary')}
          </Link>
          <div className='notifications'>
            <NotificationsUnreadIcon />
          </div>
          <div className='avatar-item'>
            <AccountCircleOutlinedIcon fontSize='large' />
          </div>
        </div>
      </Container>
    </div>
  );
};
export default AppBar;
