import { configureStore } from '@reduxjs/toolkit';
import companiesReducer from './slices/companies/companiesSlice';
import categoriesReducer from './slices/categories/categoriesSlice';
import userReducer from './slices/user/userSlice';
import documentsReducer from './slices/documents/documentsSlice';
import userSigningInfoReducer from './slices/user-signing-info/userSigningInfoSlice';
import documentContractsReducer from './slices/document-contracts/documentContractsSlice';
import documentTemplatesReducer from './slices/document-templates/documentTemplatesSlice';
import employeesReducer from './slices/employees/employeesSlice';
import suppliersReducer from './slices/suppliers/suppliersSlice';
import customersReducer from './slices/customers/customersSlice';
import designSwitchReducer from './slices/design-switch/designSwitchSlice';
import checkedReducer from './slices/document-version-check/checkedSlice';

const store = configureStore({
  reducer: {
    companies: companiesReducer,
    categories: categoriesReducer,
    user: userReducer,
    documents: documentsReducer,
    userSigningInfo: userSigningInfoReducer,
    documentContracts: documentContractsReducer,
    documentTemplates: documentTemplatesReducer,
    employees: employeesReducer,
    suppliers: suppliersReducer,
    customers: customersReducer,
    designUpdated: designSwitchReducer,
    checked: checkedReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
