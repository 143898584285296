import React, { useEffect, useRef, useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { SettingsMenu } from './components/SettingsMenu';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { fetchDocuments, selectAllDocuments } from '../../slices/documents/documentsSlice';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { DocumentContentTab } from './components/tabs/DocumentContentTab';
import { DocumentSummaryTab } from './components/tabs/DocumentSummaryTab';
import { DocumentVersionsTab } from './components/tabs/DocumentVersionsTab';
import { DocumentLabelsTab } from './components/tabs/DocumentLabelsTab';
import { DocumentTemplateTab } from './components/tabs/DocumentTemplateTab';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { NWClient } from '../../client/NWClient';
import { AIAssistModel } from '../../models/ai.assist.model';
import { DocumentVaultItemModel } from '../../models/document.vault.item.model';
import { toast } from 'react-toastify';
import { DocumentHtmlDataModel } from '../../models/document.html.data.model';
import { DocumentTemplateModel } from '../../models/document.template.model';
import { AIDocumentContractModel } from '../../models/ai.document.contract.model';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  fetchDocumentContracts,
  selectAllDocumentContracts,
} from '../../slices/document-contracts/documentContractsSlice';
import { selectUser } from '../../slices/user/userSlice';
import CheckIcon from '@mui/icons-material/Check';
import { setChecked } from '../../slices/document-version-check/checkedSlice';

type DocumentTypeItem = AIDocumentContractModel & DocumentVaultItemModel;

const DocumentItemPage = () => {
  const { documentId, category } = useParams();
  const [params] = useSearchParams();
  const tab = params.get('initialTab');
  const contractGenerated = params.get('contractGenerated');
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectUser);
  const location = useLocation();
  const path = location.pathname;
  const { t } = useTranslation();
  const token = localStorage.getItem('access_token');
  const documentsArray = useAppSelector(selectAllDocuments);
  const documentContractsArray = useAppSelector(selectAllDocumentContracts);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [summary, setSummary] = useState('');
  const [documentHTML, setDocumentHTML] = useState('');
  const [documentItem, setDocumentItem] = useState<DocumentTypeItem | null>(null);
  const [templateItem, setTemplateItem] = useState<DocumentTemplateModel | null>(null);
  const [currentTab, setCurrentTab] = useState(tab ? Number(tab) : 0);
  const [isEditable, setIsEditable] = useState(false);
  const [docName, setDocName] = useState('');
  const checked = useAppSelector((state) => state.checked.checked);
  const h1ref = useRef(null);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    navigate(`${path}?initialTab=${newValue}${contractGenerated ? '&contractGenerated=true' : ''}`);
  };

  const handleInputChange = (event: React.FocusEvent<HTMLHeadingElement>) => {
    setDocName(h1ref.current.innerText);
  };

  useEffect(() => {
    localStorage.setItem('checked', checked.toString());
  }, [checked]);

  const handleSubmit = () => {
    NWClient.put(
      token,
      'document-contract',
      Number(documentId),
      { name: docName, user: currentUser.id },
      true
    ).then(() => {
      setIsEditable(!isEditable);
      getItemName();
    });
  };

  const getItemName = () => {
    if (documentItem) {
      return documentItem.name;
    } else if (templateItem) {
      return (
        documentsArray.find((el) => el.id === templateItem.document_upload)?.name +
        `_${templateItem.updated}`
      );
    } else if (contractGenerated === 'true') {
      return documentContractsArray?.find((el) => el.id === Number(documentId))?.name;
    } else {
      return t('documentPage.headerDefault');
    }
  };

  useEffect(() => {
    dispatch(fetchDocuments({ token, data: { user: currentUser.id } }));
    if (contractGenerated === 'true') {
      dispatch(fetchDocumentContracts({ token, data: { user: currentUser.id } }));
      NWClient.get(token, 'document-contract', documentId).then((res: DocumentTypeItem) => {
        setDocumentHTML(res.html);
        setDocumentItem(res);
        setLoading(false);
      });
    } else {
      NWClient.get(token, 'document-upload', documentId).then((res: DocumentTypeItem) => {
        setDocumentItem(res);
      });
    }
    getItemName();
    setDocName(getItemName());
  }, []);

  useEffect(() => {
    if (documentItem) {
      NWClient.get(
        token,
        'ai-assist',
        Math.max(
          ...documentItem[
            'open_ai_assist_document_contract' in documentItem
              ? 'open_ai_assist_document_contract'
              : 'open_ai_assist_document_upload'
          ]
        )
      )
        .then((res: AIAssistModel) => {
          setSummary(res.content);
          if (!contractGenerated) {
            NWClient.get(
              token,
              'document-upload-html-data',
              checked
                ? Math.max(...documentItem.document_upload_html)
                : Math.min(...documentItem.document_upload_html)
            )
              .then((res: DocumentHtmlDataModel) => {
                setDocumentHTML(res.html);
              })
              .catch((error) => {
                if (error.response && error.response.status !== 404) {
                  toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
                }
              })
              .finally(() => setLoading(false));
          }
        })
        .catch((error) => {
          if (error.response && error.response.status !== 404) {
            toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
          } else {
            if (!contractGenerated) {
              NWClient.get(
                token,
                'document-upload-html-data',
                checked
                  ? Math.max(...documentItem.document_upload_html)
                  : Math.min(...documentItem.document_upload_html)
              )
                .then((res: DocumentHtmlDataModel) => {
                  setDocumentHTML(res.html);
                })
                .catch((error) => {
                  if (error.response && error.response.status !== 404) {
                    toast.error(error.message || t('messages.errorOccurred'), {
                      theme: 'colored',
                    });
                  }
                })
                .finally(() => setLoading(false));
            }
          }
        });
    }
  }, [documentItem]);

  useEffect(() => {
    if (tab) {
      setCurrentTab(Number(tab));
    }
  }, [tab]);

  useEffect(() => {
    if (documentItem) {
      NWClient.get(
        token,
        'document-upload-html-data',
        checked
          ? Math.max(...documentItem.document_upload_html)
          : Math.min(...documentItem.document_upload_html)
      ).then((res: DocumentHtmlDataModel) => {
        setDocumentHTML(res.html);
      });
    }
  }, [checked]);

  return (
    <>
      <div>
        {isEditable ? (
          <div className='flex align-items-center' style={{ marginBottom: '1rem' }}>
            <h1
              contentEditable={isEditable}
              suppressContentEditableWarning={true}
              ref={h1ref}
              onBlur={handleInputChange}
              onChange={handleInputChange}
              style={{
                outline: 'none',
                border: 'groove',
                borderRadius: '8px',
                padding: '7px',
                display: 'inline-block',
                borderColor: 'rgba(0, 0, 0, 0.1)',
              }}
            >
              {docName ? docName : getItemName()}
            </h1>
            <IconButton
              onClick={handleSubmit}
              className='ml-1 mb-3'
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <CheckIcon />
            </IconButton>
          </div>
        ) : (
          <>
            <div className='d-flex mb-4'>
              <h1>{docName ? docName : getItemName()}</h1>

              {contractGenerated && (
                <IconButton
                  className='ml-1'
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={() => setIsEditable(!isEditable)}
                >
                  <ModeEditIcon />
                </IconButton>
              )}
            </div>
          </>
        )}
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={2}>
          <SettingsMenu value={currentTab} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} md={10}>
          <div className='setting-panel mb-5'>
            {currentTab === 0 && <DocumentSummaryTab currentTab={currentTab} summary={summary} />}
            {currentTab === 1 && (
              <DocumentContentTab
                currentTab={currentTab}
                documentUploadId={templateItem ? templateItem.document_upload : null}
                documentName={documentItem ? documentItem.name : ''}
                htmlContent={documentHTML}
                checked={checked}
              />
            )}

            {currentTab === 2 && (
              <DocumentVersionsTab
                documentName={documentItem ? documentItem.name : ''}
                currentTab={currentTab}
                checked={checked}
                setChecked={(checked: boolean) => dispatch(setChecked(checked))}

              />
            )}

            <DocumentLabelsTab currentTab={currentTab} />

            {currentTab === 4 && (
              <DocumentTemplateTab
                currentTab={currentTab}
                documentName={documentItem ? documentItem.name : ''}
                htmlContent={documentHTML}
                documentUploadHtmlId={
                  documentItem && !contractGenerated
                    ? Math.max(...documentItem.document_upload_html)
                    : null
                }
              />
            )}
          </div>
        </Grid>
      </Grid>
      {loading ? <ProgressOverlay /> : null}
    </>
  );
};
export default DocumentItemPage;
