import { createTheme } from '@mui/material/styles';

const black = '#000';
export const primaryColor = '#6414db';
const defaultColor = '#C7C6C6';
const borderColorDefault = '#000';
const white = '#fff';
const errorColor = '#d32f2f';

const inputHeight = '37px';
const inputPaddingHorizontal = '0.75rem';

const labelInputText = {
  fontSize: '1.0625rem',
  lineHeight: '1.25rem',
};

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    info: {
      main: defaultColor,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1440,
      xl: 1536,
    },
  },
  components: {
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
    },

    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...labelInputText,
          position: 'static',
          transform: 'none',
          marginBottom: '0.5rem',
          color: black,
          transition: 'color 0.3s',
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          height: inputHeight,
          color: black,
          border: '1px solid ' + borderColorDefault,
          borderRadius: '6px',
          transition: 'color 0.3s, border-color 0.3s',

          '&.Mui-focused': {
            borderColor: primaryColor,
            color: primaryColor,
          },
          '&.Mui-error': {
            borderColor: errorColor,
            color: errorColor,
          },
        },
        input: {
          ...labelInputText,
          display: 'block',
          boxSizing: 'border-box',
          height: '35px',
          padding: '0' + inputPaddingHorizontal,
          borderRadius: '6px',
        },
        adornedEnd: {
          paddingRight: '0 !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
        },
        input: {
          padding: '0 ' + inputPaddingHorizontal,
        },
        notchedOutline: {
          display: 'none',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: '400',
          textTransform: 'none',
          borderRadius: '30px',

          '&.Mui-disabled': {
            opacity: '0.5',
          },
        },
        containedSizeLarge: {
          height: '50px',
          paddingRight: '3.125rem',
          paddingLeft: '3.125rem',
          fontSize: '1.0625rem',
          lineHeight: '1.25rem',
        },
        containedSizeMedium: {
          height: '40px',
          paddingRight: '1.5625rem',
          paddingLeft: '1.5625rem',
          fontSize: '1.0625rem',
          lineHeight: '1.25rem',
        },
        containedPrimary: {
          '&.Mui-disabled': {
            color: white,
            backgroundColor: primaryColor,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '0',
        },
      },
    },
  },
});
export default theme;
