import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppBar, IconButton, Toolbar, Menu, MenuItem, Grid } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { NwBreadCrumbs } from '../nw-breadcrumbs';
import { NWClient } from '../../client/NWClient';

import { ROUTES } from '../constants/routes';
import { logout } from '../../slices/user/userSlice';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import { ProgressOverlay } from '../progress-overlay/ProgressOverlay';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useAppSelector } from '../hooks/useAppSelector';
import { switchDesign } from '../../slices/design-switch/designSwitchSlice';

const NWAppBar = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const designUpdated = useAppSelector((state) => state.designUpdated.designUpdated);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [pendingStatus, setPendingStatus] = useState(false);
  const { t } = useTranslation();
  const token = localStorage.getItem('access_token');
  const location = useLocation();
  const path = location.pathname;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(switchDesign(event.target.checked));
  };

  const handleLogOut = () => {
    handleUserMenuClose();
    NWClient.logOut(token).finally(() => {
      setPendingStatus(true);
      localStorage.removeItem('access_token');
      navigate(ROUTES.LOGIN);
      dispatch(logout());
    });
  };

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const navigateToPage = (url: string) => {
    navigate(url);
    handleUserMenuClose();
  };

  return (
    <>
      <AppBar
        position='static'
        color='transparent'
        className='nw-app-bar'
        variant='outlined'
        elevation={0}
      >
        <Toolbar className='nw-app-bar-toolbar'>
          <Grid className='content'>
            <Grid>
              <NwBreadCrumbs />
            </Grid>
            <Grid className='right'>
              {path === ROUTES.INDEX && process.env.ENV_TYPE !== 'PROD' && (
                <FormControlLabel
                  control={<Switch checked={designUpdated} onChange={handleChange} />}
                  label={'Updated design version'}
                />
              )}
              <IconButton
                size='large'
                className='nw-app-bar-toolbar-user-icon'
                aria-label='account of current user'
                aria-controls='user-menu-app-bar'
                aria-haspopup='true'
                onClick={handleUserMenu}
                color='inherit'
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id='user-menu'
                anchorEl={userMenuAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                open={Boolean(userMenuAnchorEl)}
                onClose={handleUserMenuClose}
              >
                <MenuItem onClick={() => navigateToPage(ROUTES.SETTINGS)}>Account details</MenuItem>
                <MenuItem onClick={() => handleLogOut()}>{t('buttons.logout')}</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {pendingStatus && <ProgressOverlay />}
    </>
  );
};
export default NWAppBar;
