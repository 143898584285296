import React from 'react';
import { ListItemButtonProps } from './types';
import { Button, Typography, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const ListItemButton = ({ onClick, buttonText }: ListItemButtonProps) => {
  return (
    <Button
      variant='outlined'
      className='list-item-button'
      onClick={onClick}
      endIcon={<AddCircleOutlineIcon fontSize='large' className='add-circle-icon' />}
    >
      <span className='button-text'>{buttonText}</span>
    </Button>
  );
};

export default ListItemButton;
