import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CheckedState {
  checked: boolean;
}

const initialState: CheckedState = {
  checked: true,
};

const checkedSlice = createSlice({
  name: 'checked',
  initialState,
  reducers: {
    setChecked: (state, action: PayloadAction<boolean>) => {
      state.checked = action.payload;
      localStorage.setItem('checked', action.payload.toString());
    },
  },
});

export const { setChecked } = checkedSlice.actions;
export default checkedSlice.reducer;
