import React from 'react';
import { List, ListItem, Typography, Divider, Box } from '@mui/material';
import { ListItemBlockProps } from './types';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import StarIcon from '../../../static/assets/svg/kid_star.svg';

const ListItemBlock = ({
  headerText,
  withLinkButton,
  listArray,
  onListItemClick,
  linkURL,
}: ListItemBlockProps) => {
  return (
    <div className='list-item-block' style={{ backgroundColor: '#fff', borderRadius: '8px' }}>
      {(headerText || withLinkButton) && (
        <Box
          className='list-header'
          sx={{
            justifyContent: headerText ? 'space-between' : 'flex-end',
          }}
        >
          {headerText && (
            <Typography variant='h2' className='header-text'>
              {headerText}
            </Typography>
          )}
          {withLinkButton && (
            <Link to={linkURL}>
              <AddIcon className='add-icon' style={{ cursor: 'pointer' }} />
            </Link>
          )}
        </Box>
      )}
      <List
        className='list-content'
        sx={{
          padding: headerText || withLinkButton ? '0 16px 16px' : '16px',
        }}
      >
        {listArray.map((item) => (
          <React.Fragment key={item.id}>
            <ListItem
              className='list-item'
              onClick={onListItemClick}
              sx={{
                cursor: onListItemClick ? 'pointer' : 'initial',
                '&:hover': {
                  bgcolor: '#F8F9FC',
                },
              }}
            >
              <Typography className='item-name'>{item.name}</Typography>
              <Box className='item-details'>
                <Typography className='label-text'>{item.labelText}</Typography>
                {item.isFavourite && <StarIcon />}
              </Box>
            </ListItem>
            <Divider className='item-divider' />
          </React.Fragment>
        ))}
      </List>
    </div>
  );
};

export default ListItemBlock;
