import React from 'react';
import { Routes, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './pages/layout/Layout';
import TemplateDashboard from './pages/templates/TemplateDashboard';
import CategoriesPage from './pages/categories/CategoriesPage';
import PricingPage from './pages/pricing/PricingPage';
import PlanConfirmation from './pages/pricing/PlanConfirmation';
import SignUp from './pages/signup/SignUp';
import PassConfirmation from './pages/employee-password-confirmation/PassConfirmation';
import EmailSent from './pages/email-sent/EmailSent';
import ProcessSuccess from './pages/process-success/ProcessSucess';
import Confirmation from './pages/confirmation/Confirmation';
import PasswordReset from './pages/password-reset/PasswordReset';
import LogIn from './pages/login/LogIn';
import { ROUTES } from './common/constants/routes';
import { CategoryPage } from './pages/category/CategoryPage';
import { CompanyCreate } from './pages/company-create/CompanyCreate';
import SettingsPage from './pages/settings/SettingsPage';
import { NoCompanyHandler } from './pages/company-create/components/routes/NoCompanyHandler';
import DocumentItemPage from './pages/document-item-page/DocumentItemPage';
import TemplateItem from './pages/template-item';
import { NoCompanyRedirect } from './common/no-company-redirect/NoCompanyRedirect';
import { RolesGateRedirect } from './common/roles-gate';
import { GROUPS } from './common/constants/groups';
import { useUserInfo } from './pages/templates/hooks/useUserInfo';
import EmployeeProfiles from './pages/employee-profiles/EmployeeProfiles';
import EmployeeProfilePageForAdmin from './pages/employee-profiles/EmployeeProfilePageForAdmin';
import EmployeeProfileSettingsPage from './pages/employee-profiles/EmployeeProfileSettingsPage';
import SupplierProfiles from './pages/supplier-profiles/SupplierProfiles';
import SupplierProfilePage from './pages/supplier-profiles/SupplierProfilePage';
import DocumentVault from './pages/document-vault/DocumentVault';
import DocumentsCategoryPage from './pages/document-vault/CategoryPage';
import SigningPage from './pages/signing';
import DocumentsPage from './pages/documents';
import CustomerProfiles from './pages/customer-profiles/CustomerProfiles';
import CustomerProfilePage from './pages/customer-profiles/CustomerProfilePage';
import DocumentLibraryGeneralPage from './pages/document-library-general';
import PageNotFound from './pages/page-not-found';
import DemoHome from './pages/demo-home/DemoHome';

const App = () => {
  useUserInfo();
  return (
    <Routes>
      <Route path='*' element={<PageNotFound />} />
      <Route path={ROUTES.INDEX} element={<Layout />}>
        <Route
          path={ROUTES.SIGNING_DOCUMENT}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <SigningPage />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.DOCUMENTS}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <DocumentsPage />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.CATEGORY}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <CategoryPage />
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.TEMPLATE_ITEMS}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <CategoryPage />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.SUBCATEGORY}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <CategoryPage />
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.SUBCATEGORY_LEVEL_2}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <CategoryPage />
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.CATEGORY_LIST}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <CategoriesPage />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.DOCUMENT_VAULT}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <DocumentVault />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.DOCUMENT_VAULT_CATEGORY}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <DocumentsCategoryPage />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.DOCUMENT_VAULT_CATEGORY_DOCUMENT}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <DocumentItemPage />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.PRICING}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              {/*<NoCompanyRedirect>*/}
              <PricingPage />
              {/*</NoCompanyRedirect>*/}
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.PRODUCT_PLAN_CONFIRMATION}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <PlanConfirmation />
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.INDEX}
          element={
            /*<NoCompanyRedirect>*/
            <TemplateDashboard />
            /*</NoCompanyRedirect>*/
          }
        />
        <Route
          path={ROUTES.EMPLOYEE_PROFILES}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <EmployeeProfiles />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.EMPLOYEE_PROFILE}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <EmployeeProfilePageForAdmin />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.EMPLOYEE_PROFILE_CREATE}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <EmployeeProfilePageForAdmin />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.SUPPLIER_PROFILES}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <SupplierProfiles />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.SUPPLIER_PROFILE_CREATE}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <SupplierProfilePage />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.SUPPLIER_PROFILE}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <SupplierProfilePage />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.CUSTOMER_PROFILES}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <CustomerProfiles />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.CUSTOMER_PROFILE_CREATE}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <CustomerProfilePage />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.CUSTOMER_PROFILE}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <CustomerProfilePage />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.DOCUMENT_LIBRARY}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <DocumentLibraryGeneralPage />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route
          path={ROUTES.TEMPLATE_ITEM}
          element={
            <RolesGateRedirect group={GROUPS.COMPANY_ADMIN}>
              <NoCompanyRedirect>
                <TemplateItem />
              </NoCompanyRedirect>
            </RolesGateRedirect>
          }
        />
        <Route path={ROUTES.EMPLOYEE_PROFILE_SETTINGS} element={<EmployeeProfileSettingsPage />} />
        <Route path={ROUTES.ACCEPTED_INVITE} element={<SignUp />} />
        <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
        <Route path={ROUTES.JOIN_COMPANY} element={<PassConfirmation />} />
        <Route path={ROUTES.EMAIL_SENT} element={<EmailSent />} />
        <Route path={ROUTES.PROCESS_SUCCESS} element={<ProcessSuccess text={'Confirmed.'} />} />
        <Route
          path={ROUTES.SETTINGS}
          element={
            /*<NoCompanyRedirect>*/
            <SettingsPage />
            /*</NoCompanyRedirect>*/
          }
        />
        <Route path={ROUTES.CONFIRMATION} element={<Confirmation />} />
        <Route path={ROUTES.PASSWORD_RESET} element={<PasswordReset />} />
      </Route>
      <Route path={ROUTES.LOGIN} element={<LogIn />} />
      <Route path={ROUTES.ACCEPTED_INVITE} element={<ProcessSuccess text={'Accepted'} />} />
      <Route
        path={ROUTES.COMPANY_CREATE}
        element={
          <NoCompanyHandler>
            <CompanyCreate />
          </NoCompanyHandler>
        }
      />
    </Routes>
  );
};

export default App;
