import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NWClient } from '../../client/NWClient';
import { RootState } from '../../store';

export interface UpdatedDesignState {
  designUpdated: boolean;
}

const initialState: UpdatedDesignState = {
  designUpdated: false,
};

const designSwitchSlice = createSlice({
  name: 'designUpdated',
  initialState,
  reducers: {
    switchDesign(state, action: PayloadAction<boolean>) {
      state.designUpdated = action.payload;
    },
  },
});

export default designSwitchSlice.reducer;

export const { switchDesign } = designSwitchSlice.actions;
