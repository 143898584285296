import { Box } from '@mui/material';
import React from 'react';
import { TaskItemBlockProps } from './types';
import StartOutlinedIcon from '@mui/icons-material/StartOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const TaskItem = ({ iconLeft, taskCompleted, taskText }: TaskItemBlockProps) => {
  return (
    <Box
      className={
        'task-item d-flex align-items-center' +
        (taskCompleted ? ' completed' : '') +
        (iconLeft ? ' icon-left' : '')
      }
    >
      <div>{taskText}</div>
      {!taskCompleted && <StartOutlinedIcon fontSize='small' />}
      {taskCompleted && <CheckCircleIcon fontSize='medium' />}
    </Box>
  );
};

export default TaskItem;
