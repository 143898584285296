import React from 'react';
import ListItemBlock from '../../common/list-item-block/ListItemBlock';
import { ROUTES } from '../../common/constants/routes';
import { Box, Typography, List, ListItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TaskItem from '../../common/task-item-block/task-item-block';

import ListItemButton from '../../common/list-item-button/ListItemButton';
import { ButtonGroup } from 'react-bootstrap';

const DemoHome: React.FC = () => {
  const { t } = useTranslation();
  const recentDocumentsArray = [
    { id: 1, name: 'Casual contract agreement', labelText: 'Vault', isFavourite: true },
    { id: 2, name: 'New employee contract', labelText: 'Recent used', isFavourite: false },
    { id: 3, name: 'Privacy agreement and NDA', labelText: 'Recent used', isFavourite: false },
    { id: 4, name: 'Termination', labelText: 'Recent used', isFavourite: false },
  ];
  const recentProfilesArray = [
    { id: 1, name: 'Name of employee', labelText: 'Employee' },
    { id: 2, name: 'Name of customer', labelText: 'Customer' },
    { id: 3, name: 'Name of supplier', labelText: 'Supplier' },
    { id: 4, name: 'Name of employee', labelText: 'Employee' },
  ];
  const profileButtonsArray = [
    { id: 1, buttonText: 'Employee', route: ROUTES.EMPLOYEE_PROFILES },
    { id: 2, buttonText: 'Customer', route: ROUTES.CUSTOMER_PROFILES },
    { id: 3, buttonText: 'Supplier', route: ROUTES.SUPPLIER_PROFILES },
  ];

  return (
    <div className='d-md-flex home-page-wrapper mt-4 mb-4'>
      <div className='flex-md-grow-1 d-md-flex flex-md-column'>
        <div className='d-md-flex flex-md-column'>
          <div className='flex-md-grow-1'>
            <Box className='box-with-header flex-grow-1'>
              <Box className='header-action-row d-flex justify-content-between'>
                <Typography variant='h2'>{t('headers.documents')}</Typography>
              </Box>
              <Box className='d-flex align-items-start flex-wrap gap-2 buttons-container'>
                <ListItemButton buttonText={t('buttons.store')} />
                <ListItemButton buttonText={t('buttons.sign')} />
              </Box>
            </Box>
          </div>
          <div className='flex-md-grow-1'>
            <Box className='box-with-header flex-grow-1'>
              <Box className='header-action-row d-flex justify-content-between'>
                <Typography variant='h2'>{t('headers.profiles')}</Typography>
              </Box>
              <Box className='d-flex align-items-start flex-wrap gap-2 buttons-container'>
                <ListItemButton buttonText={t('buttons.employee')} />
                <ListItemButton buttonText={t('buttons.supplier')} />
                <ListItemButton buttonText={t('buttons.customer')} />
              </Box>
            </Box>
          </div>
        </div>
        <div className='d-flex flex-grow-1 flex-column'>
          <Box className='box-with-header flex-grow-1'>
            <Box className='header-action-row d-flex justify-content-between'>
              <Typography variant='h2'>{t('headers.toDo')}</Typography>
              <Link to={null}>
                <AddIcon className='add-icon' style={{ cursor: 'pointer' }} />
              </Link>
            </Box>
            <List className='tasks-list pt-0 pb-0'>
              <ListItem>
                <TaskItem taskText={'Upload and store a document'} />
              </ListItem>
              <ListItem>
                <TaskItem taskText={'Create new document'} taskCompleted />
              </ListItem>
              <ListItem>
                <TaskItem taskText={'Add employee'} />
              </ListItem>
              <ListItem>
                <TaskItem taskText={'Create a template from  '} taskCompleted />
              </ListItem>
              <ListItem>
                <TaskItem taskText={'Create new document'} taskCompleted />
              </ListItem>
              <ListItem>
                <TaskItem taskText={'Add a supplier or customer'} />
              </ListItem>
              <ListItem>
                <TaskItem taskText={'Document summary with AI'} taskCompleted />
              </ListItem>
              <ListItem>
                <TaskItem taskText={'Start subscription'} />
              </ListItem>
            </List>
          </Box>
        </div>
      </div>
      <div className='flex-md-shrink-0 d-md-flex flex-md-column'>
        <div>
          <ListItemBlock
            headerText={'Recent documents'}
            listArray={recentDocumentsArray}
            withLinkButton
            linkURL={ROUTES.DOCUMENT_VAULT}
          />
        </div>
        <div className='flex-grow-1'>
          <ListItemBlock headerText={'Recent profiles'} listArray={recentProfilesArray} />
        </div>
      </div>
    </div>
  );
};

export default DemoHome;
